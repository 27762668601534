@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .sidebar-icon {
    @apply relative flex items-center justify-center justify-items-start sm:flex-row h-12 w-12 mt-2 mb-2 mx-auto hover:bg-gradient-to-b from-primary-700 via-primary-800 to-primary-600 text-indigo-300 hover:bg-indigo-400 hover:text-white hover:rounded-md hover:cursor-pointer;
  }

  .submit {
    animation: fadeIn 1s;
  }

  .post {
    animation: fadeIn 1s;
  }

  body {
    @apply bg-primary-800
  }

  @keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
  }
}